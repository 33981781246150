/*----FontAwesome-------*/
@import url('../fonts/font-awesome/css/font-awesome.min.css');
@import url('../fonts/fonts.css');
@import 'node_modules/direction-reveal/src/styles/direction-reveal.scss';
@import 'bootstrap/dist/css/bootstrap.css';

// Style Scss
@import 'main/modal';
@import 'main/404';
@import 'main/circle';
@import 'main/style';

// skin
@import 'main/skins/yellow';
