.quoteBox {
  position: relative;
  margin: 35px 0 35px 0;
  padding-left: 70px;

  font-size: 17px;
  font-style: italic;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 10px;

    width: 40px;
    height: 40px;
    filter: invert(1);
    margin-bottom: 20px;

    background: url('../assets/quote.svg') no-repeat 0 0 / 40px;
  }
}
